import './App.css'

import React, {Component} from 'react'
import _ from 'lodash'

const quotes = [
  {
    text: `"Everybody type in the chat 'Alex is a stupid nigger'."`,
  },
  {
    text: `"Can't wait to report your death"`,
  },
  {
    text: '"I really hope you die of cancer"',
  },
  {
    text: '"Fuck the fans"',
  },
]
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {selectedQuote: ''}
  }

  componentDidMount() {
    this.setState({
      selectedQuote: quotes[_.random(0, quotes.length - 1)].text,
    })

    setInterval(() => {
      this.setState({
        selectedQuote: quotes[_.random(0, quotes.length - 1)].text,
      })
    }, 5000)
  }

  render() {
    const {selectedQuote} = this.state
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontFamily: 'Roboto Condensed ,sans-serif',
            fontWeight: 700,
            fontSize: 40,
            textAlign: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: '#000',
              color: '#fff',
              padding: '5px 20px',
              width: 'fit-content',
              margin: '0px auto',
            }}
          >
            Inspirational Keemstar Quotes
          </div>

          <div
            style={{
              backgroundColor: '#000',
              color: '#fff',
              padding: '5px 20px',
              width: 'fit-content',
              margin: '20px auto 10px',
            }}
          >
            {selectedQuote}
          </div>
        </div>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/qP78Bu__npY?autoplay=1"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    )
  }
}

export default App
